import React, {useState} from 'react';
import "../../assets/css/style.css";
import EmailNotify from '../email-notify';

function Footer() {

    return (
        <>
            <section className="bg-light py-4 border-top">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <div className='mb-2'>
                                <h5>Newsletter</h5>
                                <EmailNotify label="SUBSCRIBE" subject="subscribe" />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 text-end d-none d-lg-block d-xl-block d-xxl-block'>
                            <div className='text-end'>
                                <h5>Connect Us</h5>
                                <a href='https://www.facebook.com/leathercraftlife' target='_blank' className='btn rwt-primary-btn btn-floating rwt-bg-primary text-white me-2' ><i className="bi bi-facebook fs-4 "></i></a>
                                <a href='https://www.instagram.com/leathercraftlife' target='_blank' className='btn rwt-primary-btn btn-floating rwt-bg-primary text-white ' ><i className="bi bi-instagram fs-4 "></i></a>
                                {/* <a href='https://www.youtube.com/@leathercraftlife/videos' className='btn btn-floating rwt-bg-primary text-white' ><i className="bi bi-youtube fs-4 "></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light">
                <div className='container'>
                    <div className='row'>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                            <div className="mb-4 mt-4"><a href="/"><img className="img-fluid" src='https://storage.googleapis.com/inhouse-ecommerce-sas-public/lclifestyle.in/generic/._791_lc-web.png' alt="Leathercraft" /></a></div>
                            <p className='footer-txt'>Srinivas Nagar, Bank Colony 26, 80 feet Main Road, 7th Cross Rd, 2nd Block, Banashankari 1st Stage, Bangalore, India 560050</p>
                            <p className='footer-txt mb-0'>Call Us: +91 74116 82676</p>
                            <p className='footer-txt'>Write Us: info@lclifestyle.in</p>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mt-3 ">
                            <h4 className="mb-4 mt-4">Company</h4>
                            <p><a href="#" className="text-decoration-none footer-txt">About Us</a></p>
                            <p><a href="#" className="text-decoration-none footer-txt">Privacy & Policy</a></p>
                            <p><a href="#" className="text-decoration-none footer-txt">Terms & Conditions</a></p>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-3 ">
                            <h4 className="mb-4 mt-4">Commercials</h4>
                            <p><span className="footer-txt">For trade or other enquiries please reach us at <strong>info@lclifestyle.in</strong></span></p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col d-lg-none d-xl-none d-xxl-none'>
                            <h5 className='mt-3 mb-2'>Connect Us</h5>
                            <a href='https://www.facebook.com/leathercraftlife' className='btn btn-floating rwt-bg-primary text-white me-2' ><i className="bi bi-facebook fs-5 "></i></a>
                            <a href='https://www.instagram.com/leathercraftlife' className='btn btn-floating rwt-bg-primary text-white ' ><i className="bi bi-instagram fs-5 "></i></a>
                            {/* <a href='https://www.youtube.com/@leathercraftlife/videos' className='btn btn-floating rwt-bg-primary text-white' ><i className="bi bi-youtube fs-5 "></i></a> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light pb-5">
                <div className='container'>
                    <div className='row'>
                        <div className='col'><h4 className="mb-4 mt-4">Useful Links</h4></div>
                    </div>
                    <div className='row'>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className='text-left'>
                                <p className='mb-0'><a href="/catalogue/1LYHNEYKQSW28Y8J/solid-wedges-for-women-brown" className="fs-14rem text-decoration-none footer-txt">Solid Wedges</a></p>
                                <p className='mb-0'><a href="/catalogue/1LYHNEYKQSW28Y8K/casual-footwear-green" className="fs-14rem text-decoration-none footer-txt">Casual footwear</a></p>
                                <p className='mb-0'><a href="/catalogue/1LYHNEYKQSW28Y4I/bellies-ballerinas" className="fs-14rem text-decoration-none footer-txt">Bellies & Ballerinas</a></p>
                                <p className='mb-0'><a href="/catalogue/1LYHNEYKQ1W23Y8M/fancy-flats-for-women-blue" className="fs-14rem text-decoration-none footer-txt">Fancy flats for women</a></p>
                                <p className='mb-0'><a href="/catalogue/1LYHNEYKQSW25Y89/fashion-footwear-brown" className="fs-14rem text-decoration-none footer-txt">Fashion footwear</a></p>
                                <p className='mb-0'><a href="/catalogue/BFG90F1FGX9ZPHCY/firstfoot-tan-shimmer-flats" className="fs-14rem text-decoration-none footer-txt">Firstfoot tan shimme</a></p>
                                <p className='mb-0'><a href="/catalogue/3G0X5PAKQJ4USVOA/fashion-black-flats" className="fs-14rem text-decoration-none footer-txt">Fashion black flats</a></p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className='text-left'>
                                <p className='mb-0'><a href="/catalogue/1LYHNEY1QSW28Y8M/block-heels-black" className="fs-14rem text-decoration-none footer-txt">Block heels</a></p>
                                <p className='mb-0'><a href="/catalogue/1LYSFEYKQSW28Y8M/trendy-block-heels-pink" className="fs-14rem text-decoration-none footer-txt">Trendy block heels</a></p>
                                <p className='mb-0'><a href="/catalogue/1LYHNIUKQSW28Y8M/block-sandals-grey" className="fs-14rem text-decoration-none footer-txt">Block sandals</a></p>
                                <p className='mb-0'><a href="/catalogue/1LYHNEYKQSW28P8O/stylish-heel-sandals-beige" className="fs-14rem text-decoration-none footer-txt">Stylish heel</a></p>
                                <p className='mb-0'><a href="/catalogue/1LYHNEYKQQW2OY8I/trendy-daily-wear-wedges-for-women-pink" className="fs-14rem text-decoration-none footer-txt">Trendy daily wear wedges</a></p>
                                <p className='mb-0'><a href="/catalogue/ISEB68F7ZP073J90/firstfoot-olive-green-flats" className="fs-14rem text-decoration-none footer-txt">Firstfoot olive green</a></p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className='text-left'>
                                <p className='mb-0'><a href="/catalogue/1LYHNEYKQVW28Y83/peep-toe-shoes-for-girls-brown" className="fs-14rem text-decoration-none footer-txt">Peep toe shoes</a></p>
                                <p className='mb-0'><a href="/catalogue/1LYHNEYKQNW28J8M/fancy-women-sandals-gold" className="fs-14rem text-decoration-none footer-txt">Fancy women sandals </a></p>
                                <p className='mb-0'><a href="/catalogue/1LYHNEYKQSW2858M/party-wear-girls-footwear-grey" className="fs-14rem text-decoration-none footer-txt">Party wear girls footwear</a></p>
                                <p className='mb-0'><a href="/catalogue/1LYHMEYKQSWK8Y83/trendy-heels-for-women-blue" className="fs-14rem text-decoration-none footer-txt">Trendy heels for women</a></p>
                                <p className='mb-0'><a href="/catalogue/1LVHHEYKQSWw8Y8M/party-wear-pumps-green" className="fs-14rem text-decoration-none footer-txt">Party wear pumps</a></p>
                                <p className='mb-0'><a href="/catalogue/QRT9C2O855KXTLT5/fashion-silver-toned-flats" className="fs-14rem text-decoration-none footer-txt">Fashion silver toned</a></p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className='text-left'>
                                <p className='mb-0'><a href="/catalogue/1LYHNBYKQSW24Y83/fashion-party-wear-wedges-pink" className="fs-14rem text-decoration-none footer-txt">Fashion party wear wedges</a></p>
                                <p className='mb-0'><a href="/catalogue/YV19TLNYCKWO5HQR/style-olive-green-sandal" className="fs-14rem text-decoration-none footer-txt">Style olive green</a></p>
                                <p className='mb-0'><a href="/catalogue/KY11J1RURWDUD6PS/creation-beige-solid-flats" className="fs-14rem text-decoration-none footer-txt">Creation beige solid</a></p>
                                <p className='mb-0'><a href="/catalogue/GT3BNDW0UZMPAT4Q/flipflops-slippers" className="fs-14rem text-decoration-none footer-txt">Flipflops & Slippers</a></p>
                                <p className='mb-0'><a href="/catalogue/W1DOQ9BQODKHGHXG/fashion-navy-blue-flats" className="fs-14rem text-decoration-none footer-txt">Fashion navy blue</a></p>
                                <p className='mb-0'><a href="/catalogue/XM5M60OTR2Z8OGDJ/fashion-light-pink-flats" className="fs-14rem text-decoration-none footer-txt">Fashion light pink</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-light p-2 border-top'>
                <div className='text-center fs-6'>
                    &copy; 2024 <a href="https://lclifestyle.com" target="_blank" className="text-decoration-none rwt-txt-dark-blue">Leathercraft Lifestyle Private LTD</a>
                </div>
            </section>
        </>

    )
}

export default Footer;
