// lib
import React, {useEffect, useMemo, useState} from 'react';
import Pagination from "react-js-pagination";
// components
import Header from '../../components/header';
import Footer from '../../components/footer';
import ProductCard from '../../components/product-card';
import TitleText from '../../components/title-text/1.0';
import NotFound from '../../components/404';
import Loading from '../../components/spinner';
// config & functions
import ajaxcall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils/index';

const Products = ({orgInfo}) => {
	let query = utils.useQuery();
	const [ProductList, setProductList] = useState([]);
	const [TotalListCount, setTotalListCount] = useState(0);
	const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	const [MetaTags, setMetaTags] = useState({});
	// get query param
	let paginationNo = query.get("page");
	let activePage = (utils.isNumeric(paginationNo)) ? parseInt(paginationNo) : 1;

	const getProductList = async (offset) => {
		setIsLoading(true);
		try {
			let apiData = await ajaxcall.getCall(`${config.api.generic}/products/web`, {domain: orgInfo.domain, offset:offset, limit: config.pagination.limitPerPage}, '');
			if(apiData && apiData.hasOwnProperty('data') && apiData.data.hasOwnProperty('records') && Array.isArray(apiData.data.records) && apiData.data.records.length > 0) {
				setProductList(apiData.data.records);
				setTotalListCount(apiData.data.totalCount);
				setIsLoading(false);
			} else {
				setIs404(true);
				setIsLoading(false);
			}
		} catch (e) {
			setIs404(true);
            setIsLoading(false);
		}
	}

	const getTags = async (data) => {
        let apiData = await ajaxcall.getCall(`${config.api.generic}/config/metatags`, {
            domain: data.domain,
            page: data.page
        }, '', {
            "Content-type": "application/json"
        });
        let json = utils.parseJson(apiData.data);
		if(json) {
            setMetaTags(json)
        } 
    }

	const handlePageChange = async (pageNumber) => {
		utils.Redirect(`/products?page=${pageNumber}`)
	}

	useEffect(() => {
		async function loadApi() {
			let offset = (activePage*config.pagination.limitPerPage)-config.pagination.limitPerPage;
			console.log(offset)
			getProductList((offset == 1) ? 0 : offset); // offset should start from 0
		}
		loadApi();
	}, []);

	useMemo(() => {
		async function loadApi() {
			getTags({domain: orgInfo.domain, page: 'products.json'});
		}
		loadApi();
	}, [])

  	return <>
		<Header title={MetaTags.title || ''} keywords={MetaTags.keywords || ''} description={MetaTags.description || ''} name={MetaTags.name || ''} type={MetaTags.type || ''} /> 
		<section className="mb-4 mt-4">
			<div className="container">
				<div className="row">
					<div className="col">
						<TitleText context={{title: 'Our versatile Inventory ', subTitle: 'Our products stand for quality and durability with the trendiest style at great discounts!'}} />
					</div>
				</div>
			</div>
		</section>
		<section className="mb-4">
			<div className="container">
				<div className="row g-1">
					{IsLoading? <div className="col-12"><Loading /></div> : Is404 ? <div className="col-12"><NotFound /></div> : (Array.isArray(ProductList) && ProductList.length > 0) ? ProductList.map(data => {
						return <div key={Math.random()} className="col-6 col-md-4 col-lg-3 px-xs-0">
							<ProductCard orgInfo={orgInfo} key={Math.random()} data={data} />
						</div>
					}) : null}
				</div>
				<div className="row">
					<div className="col-12">
                        <div className="d-flex justify-content-center mt-5">
							<Pagination
								activePage={activePage}
								itemsCountPerPage={config.pagination.limitPerPage}
								totalItemsCount={parseInt(TotalListCount)}
								pageRangeDisplayed={5}
								onChange={(e) => handlePageChange(e)}
								innerClass="pagination" 
								itemClass="page-item" 
								linkClass="page-link" 
								activeClass="active"
							/>
                        </div>
                    </div>  
				</div>
			</div>
		</section>
		<Footer />
	</>;
}

export default Products;
