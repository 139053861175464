import React from 'react';
import {useLocation} from "react-router-dom";

const LoadLayout = (fName) => {
	const Component = React.lazy(() =>
	    import(`../components/layout/${fName}.js`)
	);
	return Component;
}

const LoadModule = (mName, version) => {
	const Module = React.lazy(() =>
	    import(`../components/${mName}/${version}/`)
	);
	return Module;
}

const MrpAfterDiscount = (mrp, discount) => {
	const afterDiscount = (discount/100) * mrp;
	const sellingPrice = Math.round(mrp - afterDiscount);
	return sellingPrice;
}

const Redirect = (url) => {
	window.location.href = url;
}

const OpenInNewTab = (url) => {
	window.open(url, '_blank');
}

const parseJson = (json) => {
	try {
		return JSON.parse(json)
	} catch (e) {
		return false;
	}
}

const str2Json = (obj) => {
	return Function('"use strict";return (' + obj + ')')()
}

const useQuery = () => {
	const { search } = useLocation(); 
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const urlFriendlyStr = (str) => {
    return str.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
}

const uniqueCodePattern = (code) => {
	let pattern = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$');
	if(pattern.test(code) && code.length == 16) {
		return true;
	} else {
		return false;
	}
}

const exports = {
	LoadLayout, 
	LoadModule, 
	MrpAfterDiscount, 
	Redirect, 
	parseJson, 
	str2Json, 
	OpenInNewTab, 
	useQuery, 
	isNumeric,
	urlFriendlyStr,
	uniqueCodePattern
};
export default exports;