import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import ajaxcall from '../../utils/ajaxcall';

function EmailNotify({label, subject}) {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [SubmitButton, setSubmitButton] = useState(label);
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    
    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);
        try {
            let apiData = await ajaxcall.postCall(`${config.api.googlesheet}/write`, {
                email: data.email, 
                subject: subject, 
                sheetName: 'subscribe', 
                spreadsheetId: '1TNgkzJv-IoUYmUgVGFQeRW4kYv2nnuxUQ9v-arof8c0'
            }, '', {
                "Content-type": "application/json"
            });
            if(apiData) {
                setSubmitButton(label);
                toast.info("Your request has been received successfully. You'll hear from us soon");
                reset();
                setDisableSubmitButton(false);
            } else {
                setSubmitButton(label);
                toast.error('Something went wrong. Please try again later :(');
                setDisableSubmitButton(false);
            }
        } catch (e) {
            setSubmitButton(label);
            toast.error('Something went wrong. Please try again later :(');
            setDisableSubmitButton(false);
        }
    };

    return (
        <>
            <form action="" className="" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group">
                    <input type="text" className="form-control form-control-lg w-50" placeholder={label} {...register("email", { required: true, maxLength: 85, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                    <button type="submit" className="input-group-text rwt-primary-btn btn text-white rwt-bg-primary" disabled={DisableSubmitButton}> {SubmitButton}</button>
                </div>
                <div className="form-text fs-12rem text-danger">{errors.email?.type === "required" && "Valid email field is required"}</div>
                <div className="form-text fs-12rem text-danger">{errors.email?.type === "pattern" && "Enter valid email address"}</div>
                <div className="fs-12rem text-danger">{errors.email?.type === "maxLength" && "Email cannot be more then 85 characters"}</div>
            </form>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>

    )
}

export default EmailNotify;
