import React from 'react';

function TitleText({context}) {
    return (
        <div className="text-center">
            <div className="text-uppercase fw-bold fs-26rem">{context.title}</div>
            {(context.hasOwnProperty('subTitle') ? <div className="fs-12rem">{context.subTitle}</div>:null)}
        </div>
    )
}
export default TitleText;
