// lib
import React, {useEffect, useState} from 'react';
// components
import Loading from '../../../components/spinner';
// config & functions
import ajaxcall from '../../../utils/ajaxcall';
import config from '../../../config';
import utils from '../../../utils';

function AttributeColor({orgInfo, uniqueCode}) {    
    const [Details, setDetails] = useState();
    const [Is404, setIs404] = useState(false);
    const [IsLoading, setIsLoading] = useState(true);

	const getColor = async () => {
		setIsLoading(true);
        try {
            let apiData = await ajaxcall.getCall(`${config.api.generic}/products/attribute-color/web`, {domain: orgInfo.domain, uniqueCode: uniqueCode}, '');
            if(apiData && apiData.hasOwnProperty('data') && Array.isArray(apiData.data) && apiData.data.length > 0) {
                setDetails(apiData.data);
                setIsLoading(false);
            } else {
                setIs404(true);
                setIsLoading(false);
            }
        } catch (e) {
            setIs404(true);
            setIsLoading(false);
        }
	}

    useEffect(() => {
		async function loadApi() {
			getColor();
		}
		loadApi();
	}, []);
    
    if(IsLoading) {
        return <Loading margin={2} />
    } else if (Is404) {
        return <div>No color available</div>
    } else {
        return ((Details && Array.isArray(Details) && Details.length > 0) ? <div className="mt-1 d-flex flex-row">
            {Details.map((c, i) => {
                let name = utils.urlFriendlyStr(c.name);
                return <a alt={c.color_text} key={Math.random()} href={`/catalogue/${c.unique_code}/${name}`}><div className={`rounded-circle me-1 d-flex justify-content-center align-items-center ${(c.color_value == '#fff' || c.color_value == '#ffffff')?`text-dark`:`text-white`}`} style={{backgroundColor: c.color_value, width: '35px', height: '35px'}}>
                        {(c.unique_code === uniqueCode)?<i className="bi bi-check fs-26rem"></i>:null}
                    </div></a>
            })}
        </div> : <div className="text-secondary">No color available</div>)
    }
}

export default AttributeColor;
