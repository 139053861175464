// lib
import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
// components
import Header from '../../components/header';
import Footer from '../../components/footer'; 
import ProductMagnify from '../../components/product-magnify';
import NotFound from '../../components/404';
import Loading from '../../components/spinner';
import Error from '../../components/error';
import AttributeSize from './components/attribute-size';
import AttributeColor from './components/attribute-color';
import EmailNotify from '../../components/email-notify';
import Breadcrumb from '../../components/breadcrumb';
// config & functions
import ajaxcall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils';

function Catalogue({orgInfo}) {    
    let { uniqueCode } = useParams();
    const [Details, setDetails] = useState({});
    const [IsLoading, setIsLoading] = useState(true);
    const [Is404, setIs404] = useState(false);

	const getProduct = async () => {
		setIsLoading(true);
        if(utils.uniqueCodePattern(uniqueCode)) {
            try {
                let apiData = await ajaxcall.getCall(`${config.api.generic}/products/row/web`, {domain: orgInfo.domain, uniqueCode: uniqueCode}, '');
                if(apiData && apiData.hasOwnProperty('data') && Array.isArray(apiData.data) && apiData.data.length > 0) {
                    setDetails(apiData.data[0]);
                    setIsLoading(false);
                } else {
                    setIs404(true);
                    setIsLoading(false);
                }
            } catch (e) {
                setIs404(true);
                setIsLoading(false);
            }
        } else {
            setIs404(true);
            setIsLoading(false);
        }
	}

    // product urls 
    const urls = (Details.hasOwnProperty('product_urls'))? Details.product_urls : "";
    let buyingOptions = {};
    if(urls) {
        buyingOptions = utils.str2Json(urls);
    }
    // meta tags 
    const mt = (Details.hasOwnProperty('meta_tag'))? Details.meta_tag : "";
    let metaTags = {};
    if(mt) {
        metaTags = utils.str2Json(mt);
    }

    const handleSize = (attr) => {
        setDetails({...Details, ...attr})
    }

    useEffect(() => {
		async function loadApi() {
			getProduct();
		}
		loadApi();
	}, []);

    return (
        <>
            <Header title={metaTags.title || ''} keywords={metaTags.keywords || ''} description={metaTags.description || ''} name={metaTags.name || ''} type={metaTags.type || ''} />  
            <div className="container-fluid mt-5 mb-5">
                <div className="row d-flex justify-content-center">
                    {IsLoading? <div className="col-12"><Loading /></div> : Is404 ? <div className="col-12"><Error /></div> : <>
                        <div className="col-md-12">
                            <div className="text-center">
                                <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Products', url: '/products'}, {label: Details.name, url: false}]} />
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="card">
                                <div className="row">
                                    <div className="col-md-12 col-lg-6">
                                        <div className="images p-3">
                                            <ProductMagnify orgInfo={orgInfo} img={Details.image || ''} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-6">
                                        <div className="product p-4">
                                            <div className="mb-3">
                                                <div className="text-uppercase text-muted brand">{orgInfo.domain}</div>
                                                <h5 className="text-uppercase">{Details.name || ''}</h5>
                                                <hr className="mt-2" />
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ml-2"> 
                                                        {(parseInt(Details.discount)) ? <div>
                                                            <span className="fs-5 fw-bold">&#8377;{Details.price_after_discount}</span>
                                                            <strike className="fw-light ms-1 fs-6">&#8377;{Details.mrp || 0}</strike> 
                                                            <span className='ms-1 fs-6'>{Details.discount || 0}% Off</span>
                                                        </div> : <div className="fs-4 fw-bold">&#8377;{Details.mrp || 0}</div>}
                                                    </div>
                                                </div>
                                                <hr className="mt-2" />
                                                <div className="fs-6">{Details.description || ''}</div>
                                                <hr className="mt-2" />
                                                {/* color */}
                                                <div className="text-uppercase brand">AVAILABLE COLORS</div>
                                                    <AttributeColor orgInfo={orgInfo} uniqueCode={uniqueCode} />
                                                <hr className="mt-2" />
                                                {/* size */}
                                                <div className="text-uppercase brand">AVAILABLE SIZE</div>
                                                    <AttributeSize orgInfo={orgInfo} uniqueCode={uniqueCode} handleSizefn={handleSize} sizeId={Details.size_id} />
                                                <hr className="mt-2" />
                                                {/* buying option */}
                                                {(Details.variant_status_id == 3) ? <div>
                                                        <div className="fs-24rem rwt-txt-secondary fw-bold">Out of Stock</div>
                                                        <div className="fs-14rem mb-3">This product is <strong>Out of stock</strong>. Please subscribe our email channel, we'll notify you once the product is back in stock</div>
                                                        <EmailNotify label="NOTIFY ME" subject={`Notify ${uniqueCode}`} />
                                                    </div> : <div>
                                                    <div className="text-uppercase brand mb-1">BUYING OPTION</div>
                                                    {(buyingOptions.hasOwnProperty('flipkart') && buyingOptions.flipkart !== '') ? <a href={buyingOptions.flipkart || ''} target='_blank' className='mb-2 me-2 btn btn-md bg-white text-dark rwt-border'>
                                                        <div className='d-flex align-items-center'>
                                                            <img src='/assets/images/logo-fk.png' className='me-2' height='35px' /> <span className='fw-bold text-dark'>Buy On Flipkart</span>
                                                        </div>
                                                    </a> : null}
                                                    {(buyingOptions.hasOwnProperty('meesho') && buyingOptions.meesho !== '') ? <a href={buyingOptions.meesho || ''} target='_blank' className='mb-2 me-2 btn btn-md bg-white text-dark' style={{borderColor: '#E20090'}}>
                                                        <div className='d-flex align-items-center'>
                                                            <img src='/assets/images/logo-meesho.png' className='me-2' height='35px' /> <span className='fw-bold text-dark'>Buy On Meesho</span>
                                                        </div>
                                                    </a> : null}
                                                    {(buyingOptions.hasOwnProperty('amazon') && buyingOptions.amazon !== '') ? <a href={buyingOptions.amazon || ''} target='_blank' className='mb-2 me-2 btn btn-md bg-white text-dark' style={{borderColor: '#FFC300'}}>
                                                        <div className='d-flex align-items-center'>
                                                            <img src='/assets/images/logo-amazon.png' className='me-2' height='35px' /> <span className='fw-bold text-dark'>Buy On Amazon</span>
                                                        </div>
                                                    </a> : null}
                                                </div>}
                                                <hr className="mt-2" />
                                                <div className="border rounded border-info p-1 bg-info">
                                                    <span className="text-dark fw-bold fs-13rem">Note: </span> 
                                                    <span className="text-dark fs-13rem">We don't take direct orders on our website currently. You can place an order for our products on Meesho, Flipkart, and Amazon, as we're registered sellers on these websites.</span>
                                                </div>
                                                <div className="mt-2 border rounded border-warning p-1 bg-warning">
                                                    <span className="text-dark fw-bold fs-13rem">Important: </span>
                                                    <span className="text-dark fs-13rem">Please <span className="fw-bold">check prices</span> on all the above-mentioned portals under <span className="fw-bold">BUYING OPTION</span> as prices may vary due to services charges on the respective website</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Catalogue;
