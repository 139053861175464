import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Home from "./pages/home";
import Error from "./pages/error";
import { Route, Routes } from 'react-router-dom'
import Products from './pages/products';
import Catalogue from './pages/catalogue';
import Feedback from './pages/feedback';
import PartnerProgram from './pages/partner-program';
import SellerProgram from './pages/seller-program';
import Careers from './pages/careers';
const helmetContext = {};

function App() {
	const orgInfo = {
		// domain: window.location.host
		domain: 'lclifestyle.in'
	}
	return (
		<div>
			<HelmetProvider context={helmetContext}>
				<Routes>
					<Route path='*' element={<Error />} />
					<Route exact path='/' element={<Home orgInfo={orgInfo} />} />
					<Route exact path='/home' element={<Home orgInfo={orgInfo} />} />
					<Route path='/products' element={<Products orgInfo={orgInfo} />} />
					<Route path='/feedback' element={<Feedback orgInfo={orgInfo} />} />
					<Route path='/partner-program' element={<PartnerProgram orgInfo={orgInfo} />} />
					<Route path='/seller-program' element={<SellerProgram orgInfo={orgInfo} />} />
					<Route path='/careers' element={<Careers orgInfo={orgInfo} />} />
					<Route path='/catalogue/:uniqueCode/:name' element={<Catalogue orgInfo={orgInfo} />} />
				</Routes>
			</HelmetProvider>
		</div>
	);
}

export default App;
