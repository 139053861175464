import React from 'react'

function SomethingWentWrong() {
    return (
        <div className="container">
			<div className="text-center py-5">
				<h1>Oh no! Something went wrong :(</h1>
				<p>We'll looking into this issue on priority. Please get back after sometime</p>
				<a className="btn btn-md rwt-bg-secondary text-white" href="/home" role="button">Back to home</a>
			</div>
		</div>
    )
}

export default SomethingWentWrong