import React from "react";
import config from '../../config/index';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

function ProductMagnify({orgInfo, img}) {
	if(img) {
		let imgArr = img.split(",");
		if(Array.isArray(imgArr) && imgArr.length > 0) {
			let images = [];
			imgArr.map(data => {
				images.push({original: `${config.assets.products}/${orgInfo.domain}/products/large/${data}`, thumbnail: `${config.assets.products}/${orgInfo.domain}/products/thumbnail/${data}`})
			});

			return(
				<ImageGallery items={images} thumbnailPosition={"bottom"} showPlayButton={false}/>
			)
		} else {
			return null;
		}
	} else {
		return null;
	}
}
export default ProductMagnify;