import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({title, keywords, description, name, type}) {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} data-react-helmet="true" />
			<meta name="keywords" content={keywords} data-react-helmet="true" />
			<meta property="og:type" content={type} data-react-helmet="true" />
			<meta property="og:title" content={title} data-react-helmet="true" />
			<meta property="og:description" content={description} data-react-helmet="true" />
			<meta name="twitter:creator" content={name} data-react-helmet="true" />
			<meta name="twitter:card" content={type} data-react-helmet="true" />
			<meta name="twitter:title" content={title} data-react-helmet="true" />
			<meta name="twitter:description" content={description} data-react-helmet="true" />
		</Helmet>
	)
}