import React from 'react';
import SEO from '../seo';
import "../../assets/css/style.css"

function Header({title, keywords, description, name, type}) {
	return (
		<>
            <SEO
                title={title}
                keywords={keywords}
                description={description}
                name={name}
                type={type}
            />
			<nav className="navbar navbar-light shadow-sm p-2 bg-white rounded sticky-top border-bottom navbar-expand-md justify-content-center">
                <div className="container">
                    <a href="/" className="navbar-brand d-flex me-auto"> 
                        <img className='d-none d-sm-block' src='https://storage.googleapis.com/inhouse-ecommerce-sas-public/lclifestyle.in/generic/._791_lc-web.png' alt='RapidWild' />
                        <img className='d-block d-sm-none' src='https://storage.googleapis.com/inhouse-ecommerce-sas-public/lclifestyle.in/generic/._791_lc-web.png' alt='RapidWild' style={{maxWidth: '200px'}} />
                    </a>
                    <button className="navbar-toggler btn btn-sm rwt-primary-btn text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapsingNavbar">MENU</button>
                    <div className="navbar-collapse collapse" id="collapsingNavbar">
                        <ul className="navbar-nav mx-auto-- ms-auto">
                            <li className="nav-item">
                                <a className="nav-link fw-bold text-dark" href="/">HOME</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link fw-bold text-dark" href="/products">SHOP</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link fw-bold text-dark" href="/careers">CAREERS</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link fw-bold text-dark" href="/feedback">FEEDBACK</a>
                            </li>
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link fw-bold text-dark dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    MORE
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item fw-bold text-dark" href="/careers">CAREERS</a></li>
                                    <li><a className="dropdown-item fw-bold text-dark" href="/feedback">FEEDBACK</a></li>
                                </ul>
                            </li> */}
                        </ul>
                        {/* <form action="" className="d-flex">
                            <div className="input-group">
                                <input type="text" className="form-control form-control-lg" placeholder="Search Here" />
                                <button type="submit" className="input-group-text rwt-primary-btn"> Search</button>
                            </div>
                        </form> */}
                    </div>
                </div>
            </nav>
		</>
	)
};

export default Header;
