// libs
import React, {useState, useMemo} from 'react';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
// components
import Header from '../../components/header';
import Footer from '../../components/footer';
import TitleText from '../../components/title-text/1.0';
import 'react-toastify/dist/ReactToastify.css';
// config & functions
import ajaxcall from '../../utils/ajaxcall';
import config from '../../config/index';
import utils from '../../utils/index';

function SellerProgram({orgInfo}) {

    const [InputFields, setInputFields] = useState({
        name: "",
        phoneNo: "",
        email: "",
        state: "",
        city: "",
        message: ""
    });
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [SubmitButton, setSubmitButton] = useState('SAVE RECORD');
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [MetaTags, setMetaTags] = useState({});

    const updateInputFields = ((key, val) => {
        let newData = { ...InputFields }
        newData[key] = val
        setInputFields(newData)
    })

    const handleReferredByChange=((val)=>{ 
        updateInputFields('referredBy', val.target.value) 
    })

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);
        try {
            let apiData = await ajaxcall.postCall(`${config.api.googlesheet}/write`, {
                name: data.name,
                phoneNo: data.phoneNo,
                email: data.email,
                state: data.state,
                city: data.city,
                message: data.message,
                sheetName: 'sellerProgram',
                spreadsheetId: '1TNgkzJv-IoUYmUgVGFQeRW4kYv2nnuxUQ9v-arof8c0'
            }, '', {
                "Content-type": "application/json"
            });
            if(apiData) {
                setSubmitButton('SAVE RECORD');
                toast.info('Thank you for your registration. Our team will get back to you soon.');
                reset();
                setDisableSubmitButton(false);
            } else {
                setSubmitButton('SAVE RECORD');
                toast.error('Something went wrong. Please try again later :(');
                setDisableSubmitButton(false);
            }
        } catch (e) {
            setSubmitButton('SAVE RECORD');
            toast.error('Something went wrong. Please try again later :(');
            setDisableSubmitButton(false);
        }
    };
    
    const getTags = async (data) => {
        let apiData = await ajaxcall.getCall(`${config.api.generic}/config/metatags`, {
            domain: data.domain,
            page: data.page
        }, '', {
            "Content-type": "application/json"
        });
        let json = utils.parseJson(apiData.data);
		if(json) {
            setMetaTags(json)
        } 
    }

    useMemo(() => {
        async function loadApi() {
			getTags({domain: orgInfo.domain, page: 'seller-program.json'});
		}
		loadApi();
    }, [])

  	return <>
		<Header title={MetaTags.title || ''} keywords={MetaTags.keywords || ''} description={MetaTags.description || ''} name={MetaTags.name || ''} type={MetaTags.type || ''} /> 
        <section className="mb-4 mt-4">
			<div className="container">
				<div className="row">
					<div className="col">
						<TitleText context={{title: 'Seller Account Management Services!', subTitle: 'Start selling now!!!'}} />
					</div>
				</div>
			</div>
		</section>
        <section className="mb-4">
			<div className="container">
                <div className="row">
                    <div className="col">
                        <div className="my-4">
                            <p className="text-dark">RapidWild Expo provides a comprehensive <strong>Account Management Service</strong> for India's top e-commerce platforms such as <strong><u>Flipkart, Amazon, Meesho, Ebay, Etsy, JioMart</u>,</strong> and more, allowing you to list your products on multiple marketplaces. Our services include account opening, product listing, promotion, and order management, making it easier for you to sell online or start an online business. Contact us today for all your e-commerce needs.</p>
                        </div>
                    </div>
                </div>
				<div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="my-4 d-flex flex-row">
                            <div>
                                <h3>Why choose RapidWild?</h3>
                            </div>
                        </div>
                        <div className="my-4 d-flex flex-row">
                            <div className="pe-3"><i className="bi bi-check2-all fs-3"></i></div>
                            <div>
                                <h3>We are registered seller on top e-commerce platforms</h3>
                                <p className="text-secondary">As authorized sellers on the top e-commerce platforms, you can trust us to provide you with high-quality products and exceptional customer service. Our registration ensures that we meet the strict standards of these marketplaces, giving you confidence in your journey.</p>
                            </div>
                        </div>
                        <div className="my-4 d-flex flex-row">
                            <div className="pe-3"><i className="bi bi-check2-all fs-3"></i></div>
                            <div>
                                <h3>We have 4.6-star rating on Meesho</h3>
                                <p className="text-secondary">Our Meesho store has achieved an impressive 4.6-star rating, demonstrating our commitment to providing top-notch products and service. You can rely on us to deliver the same level of excellence to your store.</p>
                            </div>
                        </div>
                        <div className="my-4 d-flex flex-row">
                            <div className="pe-3"><i className="bi bi-check2-all fs-3"></i></div>
                            <div>
                                <h3>Skilled professional team in digital promotion to online sales</h3>
                                <p className="text-secondary">Our team of experienced professionals has a deep understanding of the market's needs and is committed to delivering results, from <strong>digital promotion to online sales</strong>. You can trust us to provide reliable and effective services to meet your business goals.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <form className="p-4 bg-light border" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col">
									<div className="pb-4 fw-bold">Begin your online selling journey from the comfort of your own home with ease. Register Now to start.</div>
								</div>
							</div>
                            <div className="row g-3">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label htmlFor="inputName" className="form-label">Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="inputName" defaultValue={InputFields.name} {...register("name", { required: true, maxLength: 85 })} />
                                    <div className="fs-12rem text-danger">{errors.name?.type === "required" && "Name is Mandatory"}</div>
                                    <div className="fs-12rem text-danger">{errors.name?.type === "maxLength" && "Name cannot be more then 85 characters"}</div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label htmlFor="inputPhone" className="form-label">Phone No <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="inputPhone" defaultValue={InputFields.phoneNo} {...register("phoneNo", { required: true, minLength: 10, maxLength: 12 })} />
                                    <div className="fs-12rem text-danger">{errors.phoneNo?.type === "required" && "Phone No is Mandatory"}</div>
                                    <div className="fs-12rem text-danger">{errors.phoneNo?.type === "minLength" && "Phone No cannot be less then 10 characters"}</div>
                                    <div className="fs-12rem text-danger">{errors.phoneNo?.type === "maxLength" && "Phone No cannot be more then 12 characters"}</div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label htmlFor="inputEmail" className="form-label">Email address (optional)</label>
                                    <input type="email" className="form-control" id="inputEmail" aria-describedby="emailHelp" defaultValue={InputFields.email} {...register("email", { required: false, maxLength: 85, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                                    <div id="emailHelp" className="form-text fs-12rem text-danger">{errors.email?.type === "pattern" && "Enter valid email address" || "We'll never share your email with anyone else."}</div>
                                    <div className="fs-12rem text-danger">{errors.email?.type === "maxLength" && "Email cannot be more then 85 characters"}</div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label htmlFor="inputState" className="form-label">State <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="inputState" aria-describedby="stateHelp" defaultValue={InputFields.state} {...register("state", { required: true, maxLength: 85 })} />
                                    <div id="stateHelp" className="form-text fs-12rem text-danger">{errors.state?.type === "required" && "Enter valid State address"}</div>
                                    <div className="fs-12rem text-danger">{errors.state?.type === "maxLength" && "State cannot be more then 85 characters"}</div>
                                </div>
								<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label htmlFor="inputCity" className="form-label">City <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="inputCity" aria-describedby="cityHelp" defaultValue={InputFields.city} {...register("city", { required: true, maxLength: 85 })} />
                                    <div id="cityHelp" className="form-text fs-12rem text-danger">{errors.city?.type === "required" && "Enter valid City address"}</div>
                                    <div className="fs-12rem text-danger">{errors.city?.type === "maxLength" && "City cannot be more then 85 characters"}</div>
                                </div>
                            </div>
                            <div className="row g-3 mt-2">
                                <div className="col">
                                    <label htmlFor="inputName" className="form-label">Message <span className="text-danger"></span></label>
                                    <textarea className="form-control" rows="4" defaultValue={InputFields.message} {...register("message", { required: false, minLength: 20, maxLength: 200 })}></textarea>
                                    <div className="fs-12rem text-danger">{errors.message?.type === "required" && "Message is Mandatory"}</div>
                                    <div className="fs-12rem text-danger">{errors.message?.type === "minLength" && "Enter atleast 20 characters"}</div>
                                    <div className="fs-12rem text-danger">{errors.message?.type === "maxLength" && "Message cannot be more then 200 characters"}</div>
                                </div>
                            </div>
                            <button type="submit" disabled={DisableSubmitButton} className=" rwt-primary-btn btn text-white rwt-bg-primary mt-3">{SubmitButton}</button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </section>
		<Footer />
	</>;
}

export default SellerProgram;
