// libs
import React, {useState, useMemo} from 'react';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
// components
import Header from '../../components/header';
import Footer from '../../components/footer';
import TitleText from '../../components/title-text/1.0';
import 'react-toastify/dist/ReactToastify.css';
// config & functions
import ajaxcall from '../../utils/ajaxcall';
import config from '../../config/index';
import utils from '../../utils/index';

function PartnerProgram({orgInfo}) {

    const [InputFields, setInputFields] = useState({
        name: "",
        phoneNo: "",
        email: "",
        referredBy: "",
        comments: ""
    });
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [SubmitButton, setSubmitButton] = useState('SAVE RECORD');
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [MetaTags, setMetaTags] = useState({});

    const updateInputFields = ((key, val) => {
        let newData = { ...InputFields }
        newData[key] = val
        setInputFields(newData)
    })

    const handleReferredByChange=((val)=>{ 
        updateInputFields('referredBy', val.target.value) 
    })

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);
        try {
            let apiData = await ajaxcall.postCall(`${config.api.googlesheet}/write`, {
                name: data.name,
                phoneNo: data.phoneNo,
                email: data.email,
                referredBy: data.referredBy,
                comments: data.comments,
                sheetName: 'partnerProgram',
                spreadsheetId: '1TNgkzJv-IoUYmUgVGFQeRW4kYv2nnuxUQ9v-arof8c0'
            }, '', {
                "Content-type": "application/json"
            });
            if(apiData) {
                setSubmitButton('SAVE RECORD');
                toast.info('Thank you for your registration. Our team will get back to you soon.');
                reset();
                setDisableSubmitButton(false);
            } else {
                setSubmitButton('SAVE RECORD');
                toast.error('Something went wrong. Please try again later :(');
                setDisableSubmitButton(false);
            }
        } catch (e) {
            setSubmitButton('SAVE RECORD');
            toast.error('Something went wrong. Please try again later :(');
            setDisableSubmitButton(false);
        }
    };

    const getTags = async (data) => {
        let apiData = await ajaxcall.getCall(`${config.api.generic}/config/metatags`, {
            domain: data.domain,
            page: data.page
        }, '', {
            "Content-type": "application/json"
        });
        let json = utils.parseJson(apiData.data);
		if(json) {
            setMetaTags(json)
        } 
    }

    useMemo(() => {
        async function loadApi() {
			getTags({domain: orgInfo.domain, page: 'partner-program.json'});
		}
		loadApi();
    }, [])

  	return <>
		<Header title={MetaTags.title || ''} keywords={MetaTags.keywords || ''} description={MetaTags.description || ''} name={MetaTags.name || ''} type={MetaTags.type || ''} /> 
        <section className="mb-4 mt-4">
			<div className="container">
				<div className="row">
					<div className="col">
						<TitleText context={{title: 'Partner Program!', subTitle: 'Grow with us with our Partner Program. Register now to know more'}} />
					</div>
				</div>
			</div>
		</section>
        <section className="mb-4">
			<div className="container">
                <div className="row">
                    <div className="col">
                        <div className="my-4">
                            <p className="text-dark">If you're interested in earning a commission for referring our product, then we're happy to onboard you. Please provide your details in the registration form so that our team can get in touch with you shortly. Kindly introduce yourself by filling out the form.</p>
                        </div>
                    </div>
                </div>
				<div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="my-4 d-flex flex-row">
                            <div className="pe-3"><i className="bi bi-check2-all fs-3"></i></div>
                            <div>
                                <h3>Refer our products to your network</h3>
                                <p className="text-secondary">Spread the word about our products to your friends, family and refer our products online to earn commissions.</p>
                            </div>
                        </div>
                        <div className="my-4 d-flex flex-row">
                            <div className="pe-3"><i className="bi bi-check2-all fs-3"></i></div>
                            <div>
                                <h3>Earn commissions on sale</h3>
                                <p className="text-secondary">When someone clicks on your referral link and makes a purchase, you'll earn a commission on that sale.</p>
                            </div>
                        </div>
                        <div className="my-4 d-flex flex-row">
                            <div className="pe-3"><i className="bi bi-check2-all fs-3"></i></div>
                            <div>
                                <h3>Enjoy the benefits</h3>
                                <p className="text-secondary">By referring our products and earning commissions, you'll have the opportunity to earn extra income without any upfront investment.</p>
                            </div>
                        </div>
                        <div className="my-4 d-flex flex-row">
                            <div className="pe-3"><i className="bi bi-check2-all fs-3"></i></div>
                            <div>
                                <h3>We are 100% online</h3>
                                <p className="text-secondary">Sell our products from any corner of the word. We provide all necessary support and assets.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <form className="p-4 bg-light border" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row g-3">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label htmlFor="inputName" className="form-label">Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="inputName" defaultValue={InputFields.name} {...register("name", { required: true, maxLength: 85 })} />
                                    <div className="fs-12rem text-danger">{errors.name?.type === "required" && "Name is Mandatory"}</div>
                                    <div className="fs-12rem text-danger">{errors.name?.type === "maxLength" && "Name cannot be more then 85 characters"}</div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label htmlFor="inputPhone" className="form-label">Phone No <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="inputPhone" defaultValue={InputFields.phoneNo} {...register("phoneNo", { required: true, minLength: 10, maxLength: 12 })} />
                                    <div className="fs-12rem text-danger">{errors.phoneNo?.type === "required" && "Phone No is Mandatory"}</div>
                                    <div className="fs-12rem text-danger">{errors.phoneNo?.type === "minLength" && "Phone No cannot be less then 10 characters"}</div>
                                    <div className="fs-12rem text-danger">{errors.phoneNo?.type === "maxLength" && "Phone No cannot be more then 12 characters"}</div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                    <label htmlFor="inputEmail" className="form-label">Email address (optional)</label>
                                    <input type="email" className="form-control" id="inputEmail" aria-describedby="emailHelp" defaultValue={InputFields.email} {...register("email", { required: false, maxLength: 85, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />
                                    <div id="emailHelp" className="form-text fs-12rem text-danger">{errors.email?.type === "pattern" && "Enter valid email address" || "We'll never share your email with anyone else."}</div>
                                    <div className="fs-12rem text-danger">{errors.email?.type === "maxLength" && "Email cannot be more then 85 characters"}</div>
                                </div>
                            </div>
                            <div className="row g-3 mt-sm-1">
                                <div className="col">
                                    <label htmlFor="inputReferredBy" className="form-label">How did you come to know about us? <span className="text-danger">*</span></label>
                                    <select className="form-control" id="inputReferredBy" style={{WebkitAppearance: 'none'}} value={InputFields.referredBy} {...register("referredBy", { required: true })} onChange={handleReferredByChange}>
                                        <option value=""></option>
                                        <option value="referred-by-friend">Referred by friend</option>
                                        <option value="online-ad">Online advertisement</option>
                                        <option value="others">Others</option>
                                    </select>
                                    <div className="fs-12rem text-danger">{errors.referredBy?.type === "required" && "Referred by is Mandatory"}</div>
                                </div>
                            </div>
                            <div className="row g-3 mt-2">
                                <div className="col">
                                    <label htmlFor="inputName" className="form-label">Comments <span className="text-danger"></span></label>
                                    <textarea className="form-control" rows="4" defaultValue={InputFields.comments} {...register("comments", { required: false, minLength: 20, maxLength: 200 })}></textarea>
                                    <div className="fs-12rem text-danger">{errors.comments?.type === "required" && "Comments is Mandatory"}</div>
                                    <div className="fs-12rem text-danger">{errors.comments?.type === "minLength" && "Enter atleast 20 characters"}</div>
                                    <div className="fs-12rem text-danger">{errors.comments?.type === "maxLength" && "Comments cannot be more then 200 characters"}</div>
                                </div>
                            </div>
                            <button type="submit" disabled={DisableSubmitButton} className=" rwt-primary-btn btn text-white rwt-bg-primary mt-3">{SubmitButton}</button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </section>
		<Footer />
	</>;
}

export default PartnerProgram;
