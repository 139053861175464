// lib
import React, {useEffect, useMemo, useState, Suspense} from 'react';
// components
import Footer from '../../components/footer';
import Header from '../../components/header'; 
import Spinner from '../../components/spinner';
import Error from '../../components/error';
// config & functions
import ajaxcall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils';

function Home({orgInfo}) {
	const [Config, setConfig] = useState([]);
	const [IsLoading, setIsLoading] = useState(true);
	const [HasError, setHasError] = useState(false);
	const [MetaTags, setMetaTags] = useState({});

	const getTags = async (data) => {
        let apiData = await ajaxcall.getCall(`${config.api.generic}/config/metatags`, {
            domain: data.domain,
            page: data.page
        }, '', {
            "Content-type": "application/json"
        });
        let json = utils.parseJson(apiData.data);
		if(json) {
            setMetaTags(json)
        } 
    }

	const homeConfig = async () => {
		let apiData = await ajaxcall.getCall(`${config.api.generic}/config/homepage`, {domain: orgInfo.domain}, '');
		let json = utils.parseJson(apiData.data);
		if(json) {
			let layout = [];
			if(json && Array.isArray(json) && json.length > 0) {
				json.forEach(config => {
					layout.push({layout: utils.LoadLayout(config.layout), required: config.required, modules: config.modules});
				})
			} 
			setConfig(layout);
			setIsLoading(false);
		} else {
			setHasError(true);
			setIsLoading(false);
		}
	}

	useMemo(() => {
		async function loadApi() {
			getTags({domain: orgInfo.domain, page: 'home.json'});
			homeConfig();
		}
		loadApi();
	}, [])

	return (
		<>
			<Suspense fallback={<Spinner />}>
				<Header title={MetaTags.title || ''} keywords={MetaTags.keywords || ''} description={MetaTags.description || ''} name={MetaTags.name || ''} type={MetaTags.type || ''} /> 
				{IsLoading?<Spinner /> : HasError?<Error />:Config.map(Module => {
					if(Module.required) {
						return <Module.layout key={Math.random()} moduleConfig={Module.modules} orgInfo={orgInfo} />
					}
				})}
				<Footer/>
			</Suspense>
		</>
	)
};

export default Home;
