import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';

function Careers() {

  	return <>
		<Header />
        <div className="container">
			<div className="text-center py-5">
				<h1>We are building this page and we'll be live soon!!</h1>
				<p>Exciting career opportunities await you! Our career page is currently under construction, but will be available soon. Stay tuned for updates</p>
				<a className="btn btn-md rwt-bg-secondary text-white" href="/home" role="button">Back to home</a>
			</div>
		</div>
		<Footer />
	</>;
}

export default Careers;
