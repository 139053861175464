import React from 'react'

function Loading({margin = 5}) {
    return (
        <div>
            <div className="text-center">
                <div className={`spinner-border m-${margin}`} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )
}
export default Loading;
