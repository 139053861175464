// lib
import React, {useEffect, useState} from 'react';
// components
import Loading from '../../../components/spinner';
// config & functions
import ajaxcall from '../../../utils/ajaxcall';
import config from '../../../config';

function AttributeSize({orgInfo, uniqueCode, handleSizefn, sizeId}) {
    const [Details, setDetails] = useState([]);
    const [Is404, setIs404] = useState(false);
    const [IsLoading, setIsLoading] = useState(true);

	const getSize = async () => {
		setIsLoading(true);
        try {
            let apiData = await ajaxcall.getCall(`${config.api.generic}/products/attribute-size/web`, {domain: orgInfo.domain, uniqueCode: uniqueCode}, '');
            if(apiData && apiData.hasOwnProperty('data') && Array.isArray(apiData.data) && apiData.data.length > 0) {
                setDetails(apiData.data);
                setIsLoading(false);
            } else {
                setIs404(true);
                setIsLoading(false);
            }
        } catch (e) {
            setIs404(true);
            setIsLoading(false);
        }
	}

    useEffect(() => {
		async function loadApi() {
			getSize();
		}
		loadApi();
	}, []);

    if(IsLoading) {
        return <Loading margin={2} />
    } else if (Is404) {
        return <div>No size available</div>
    } else {
        return ((Details && Array.isArray(Details) && Details.length > 0) ? <div>

            {Details.map((s, i) => {
                return <span key={Math.random()} className="me-1" onClick={() => handleSizefn(s)}>
                    <input type="radio" className="btn-check" name="options-outlined" id={`s${i}`} autoComplete="off" defaultChecked={(parseInt(sizeId) == parseInt(s.size_id))?true:false} />
                    <label className="btn btn-outline-info mt-1" htmlFor={`s${i}`}>{s.size_text}</label>
                </span>
            })}
        </div> : <div>No size available</div>)
    }
}

export default AttributeSize;
