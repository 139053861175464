import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import SomethingWentWrong from '../components/error';

function Error() {

  	return <>
		<Header />
        <SomethingWentWrong />
		<Footer />
	</>;
}

export default Error;
