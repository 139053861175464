import React from 'react'

function Breadcrumb({list}) {
    return (
        <nav aria-label="breadcrumb" className="mb-1">
            <ol className="breadcrumb justify-content-center">
                {(list && Array.isArray(list) && list.length > 0) ? list.map(data => {
                    return <li key={Math.random()} className="breadcrumb-item fs-16rem">{(data.url) ? <a href={data.url}>{data.label}</a> : data.label}</li>
                }): null}
            </ol>
        </nav>
    )
}
export default Breadcrumb;
