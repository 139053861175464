import React from 'react'
import config from '../../config/index'
import utils from '../../utils/index'
import 'react-multi-carousel/lib/styles.css';

function Rating({count}) {
	let r = [];
	for(let i=1; i<=count; i++) {
		r.push(<i key={Math.random()} className="bi bi-star-fill rwt-txt-primary"></i>);
	}
	return r;
}

const redirect = async (code, name) => {
	let label = await utils.urlFriendlyStr(name);
	utils.Redirect(`catalogue/${code}/${label}`)
}

function ProductCard({ orgInfo, data }) {
	const sImg = (data.image) ? data.image.split(",") : "";
	let imgName = "";
	if(Array.isArray(sImg) && sImg.length > 0) {
		imgName = sImg[0]; // default take 1st image
	}
    return (
        <div role="button" className="product-card" onClick={() => redirect(data.unique_code, data.name)}>
            <div className="card" >
                <span className="wish-icon"><i className="fa fa-heart-o"></i></span>
                <div className="d-flex h-100 justify-content-center align-items-center"><img src={`${config.assets.products}/${orgInfo.domain}/products/medium/${imgName}`} className="mw-100" alt={data.name} /></div>
                <div className="card-body col-sm-12">
                    <div className="fs-6 text-truncate">{data.name}</div>
                    <div className='row'>
                        <div className='col-lg-12'>
							{(parseInt(data.discount)) ? <div>
									<span className="fs-5 fw-bold">&#8377;{data.price_after_discount}</span>
									<strike className="fw-light ms-1 fs-6">&#8377;{data.mrp}</strike> 
									<span className='ms-1 fs-6'>{data.discount}% Off</span>
								</div> : <span className="fs-5 fw-bold">&#8377;{data.mrp}</span>
							}
                        </div>
                    </div>
					<div className='row'>
						<div className='col-lg-12 text-start'>
							<Rating count={data.rating} />
                        </div>
						{/* <div className='col-lg-6 text-end'>
							<a href={`catalogue/${data.id}`} target='_blank' className='rwt-txt-secondary fw-bold'>Get Details</a>
						</div> */}
					</div>
                </div>
            </div>
        </div>
    )
}

export default ProductCard
